body {
  margin: 0;
  overflow: hidden;
  touch-action: pan-x pan-y;
}

body.red {
  background: #84261d;
}

body.white {
  background: white;
}

.letter {
  position:absolute;
}

.MuiSlider-root.red {
  color: white!important;
}

.MuiSlider-root, .MuiSlider-root.white {
  color: black!important;
}

.slider-area {
  bottom: 5%; 
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 50%;
}

.touchable-opacity {
  position:absolute;
  width: 100%;
  height: 85%;
}
